"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function () {
  var win = $(window);

  /**
   * Returns distance of given element from the the center of viewport.
   * The origin of the element is considered its top edge
   */
  function distanceFromCentreOfViewport(el) {
    return el.offset().top /*+ el.height() / 2*/ - (win.scrollTop() + win.height() / 2);
  }

  /* fake lazy load */
  jQuery.fn.extend({
    fakeLazyLoad: function fakeLazyLoad() {
      var elements = this;

      function lazyLoadRender() {
        var counter = 0;
        elements.each(function () {
          var el = $(this);
          if (el.prop("lazyVisible") == false) {
            if (distanceFromCentreOfViewport(el) < win.height() / 2 - 50) {
              el.prop("lazyVisible", true);
              setTimeout(function () {
                el.removeClass("lazy-load-hidden");
                el.addClass("lazy-load-visible");
              }, counter * 200);
              counter++;
              if (el.prop("mc")) {
                var mc = el.prop("mc");
                mc.play();
              }
            }
          }
        });
      }

      $(window).on("scroll", lazyLoadRender);

      $("<style>").prop("type", "text/css").html(".lazy-load-hidden {opacity: 0; transform: translate3d(0,30px,0);}" + " .lazy-load-visible {opacity: 1; transform: translate3d(0,0,0); transition: opacity 600ms ease 300ms, transform 600ms ease 300ms;}").appendTo("head");

      var result = this.each(function () {
        var el = $(this);
        el.addClass("lazy-load-hidden").prop("lazyVisible", false);
      });

      lazyLoadRender();

      return result;
    }
  });
})();

var app = {
  map: [],
  dropdownMenus: function dropdownMenus() {
    // ASSIGN DATA ATTRIBUTES

    $(".parent > a").on("click", function (e) {
      var selected = $(this);
      var subMenu = selected.next("ul");
      var hasSubMenu = subMenu.length == 1 ? true : false;

      if (hasSubMenu == true) {
        e.preventDefault();

        // Show
        if (selected.parent("li").hasClass("selected") == true) {
          // Hide
          selected.parent("li").removeClass("selected");
          $(".parent-sub").hide();
        } else {
          // Show
          selected.addClass("selected");
          $(".parent-sub").hide();
          $(".parent a").removeClass("selected");
          selected.parent("li").addClass("selected");
          subMenu.fadeIn();
        }
      }
    });
  },
  mobileMenu: function mobileMenu() {
    var mobileNavItems = $("#mobile-nav-items");
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
          mobileNavItems.removeClass("show");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
          mobileNavItems.addClass("show");
        }
      });
    }

    var Closed = false;

    $(".hamburger").click(function () {
      if (Closed == true) {
        $(this).removeClass("open");
        $(this).addClass("closed");
        Closed = false;
      } else {
        $(this).removeClass("closed");
        $(this).addClass("open");
        Closed = true;
      }
    });

    //	$('#mobile-nav-inner-items').hcSticky({
    //	bottom:0
    //	});
  },
  cycleItems: function cycleItems() {
    $(".rotate-items-slick").slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: '<span class="btn-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
      nextArrow: '<span class="btn-next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  },

  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  enquiryDialogForm: function enquiryDialogForm() {
    var btn = $(".enquiry_frm_btn");
    btn.click(function () {
      var selected = $(this);
      var attr = selected.attr("data-sub-title");

      var data = {
        title: "Enquire Now",
        subTitle: "",
        content: $("#enquire-frm").html()
      };

      if ((typeof attr === "undefined" ? "undefined" : _typeof(attr)) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && attr !== false) {
        data.subTitle = selected.attr("data-sub-title");
      }
      if (data.subTitle != "") {
        data.title += " - " + data.subTitle;
      }

      // Open customized confirmation dialog window
      $.fancybox.open({
        type: "html",
        src: '<div class="fc-content rounded frm-dialog">' + '<div class="top">' + "<h2>" + data.title + "</h2>" + "</div>" + '<div class="dialog-item-inner">' + data.content + "</div>" + "</div>",
        opts: {
          afterShow: function afterShow(instance, current) {
            $(".frm-dialog #submit_enquiry_btn").click(function (e) {
              e.preventDefault();
              var form = $(".dialog-item-inner form");
              var validation = $(".dialog-item-inner #validation");
              var subject = "Enquiry";

              // MODIFY SUBJECT LINE
              if ($("h1#pageTitle").length == 1) {
                subject += " on " + $("h1#pageTitle").html();
              }
              if (data.subTitle != "") {
                subject += ": " + data.subTitle;
              }

              form.find("#referer").val(document.referrer);
              form.find("#subject").val(subject);

              // CHK VALIDATION
              var valid = true;
              var inputs = form.find("input, textarea");

              inputs.removeClass("required");
              $.each(inputs, function (key, row) {
                var row = $(row);
                if (row.attr("required") && row.val() == "") {
                  row.addClass("required");
                  valid = false;
                }
                if (row.attr("type") == "email" && row.val() != "") {
                  if (app.validateEmail(row.val()) == false) {
                    row.addClass("required");
                    valid = false;
                  }
                }
              });

              var formData = form.serialize();

              if (valid == true) {
                $.ajax({
                  type: "POST",
                  url: form.attr("action"),
                  data: formData
                }).done(function (response) {
                  if (response.success == true) {
                    // SUBMISSION
                    validation.html("Your enquiry was submitted successfully.");
                    validation.removeClass("error");
                    form.hide();
                  } else {
                    // FAIL
                    validation.html("Your enquiry could not be sent.  Please try again.");
                    validation.addClass("error");
                  }
                  validation.removeClass("hidden");
                }).fail(function (response) {
                  // FAIL
                  validation.html("Your enquiry could not be sent.  Please try again.");
                  validation.addClass("error").removeClass("hidden");
                });
              }
            });
          }
        }
      });
    });
  },
  projectItems: function projectItems(filter) {
    $("#grid").isotope({
      itemSelector: ".gridder-list",
      columnWidth: 10,
      layoutMode: "fitRows",
      filter: filter === "view-all" ? "*" : "." + filter
    });
  },
  setPortfolioGallery: function setPortfolioGallery() {
    var $left = $(".left");
    var $gl = $(".gallery");
    var $gl2 = $(".gallery2");
    var $photosCounterFirstSpan = $(".photos-counter span:nth-child(1)");

    $gl2.on("init", function (event, slick) {
      $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
      $(".photos-counter span:nth-child(2)").text(slick.slideCount);
    });

    $gl.slick({
      rows: 0,
      slidesToShow: 2,
      arrows: false,
      draggable: false,
      useTransform: false,
      mobileFirst: true,
      responsive: [{
        breakpoint: 480,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 690,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 5
        }
      }]
    });

    $gl2.slick({
      rows: 0,
      useTransform: false,
      prevArrow: ".arrow-left",
      nextArrow: ".arrow-right",
      fade: true,
      asNavFor: $gl
    });

    $(".gallery .item").on("click", function () {
      var index = $(this).attr("data-slick-index");
      $gl2.slick("slickGoTo", index);
    });

    $gl2.on("afterChange", function (event, slick, currentSlide) {
      $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
    });
  },
  initMap: function initMap() {
    var markers = [];
    markers.push({
      title: "Doublejump",
      address: "Unit 10, 8 Cromac Avenue, Belfast, Antrim, BT7 2JA",
      latitude: 54.58962,
      longitude: -5.92378,
      icon: "/assets/img/marker.png"
    });
    markers.push({
      title: "Doublejump",
      address: "Taylor’s Ln, The Liberties, Dublin 8, Ireland, D08 YPP9",
      latitude: 53.341221,
      longitude: -6.2836,
      icon: "/assets/img/marker.png"
    });

    for (var x = 0; x < markers.length; x++) {
      var mapLatLng = new google.maps.LatLng(markers[x].latitude, markers[x].longitude);
      var mapOptions = {
        zoom: 13,
        center: mapLatLng,
        scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        draggable: true,
        styles: [{
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#e9e9e9" }, { lightness: 17 }]
        }, {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }, { lightness: 20 }]
        }, {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#ffffff" }, { lightness: 17 }]
        }, {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }]
        }, {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 18 }]
        }, {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }, { lightness: 16 }]
        }, {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#f5f5f5" }, { lightness: 21 }]
        }, {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#dedede" }, { lightness: 21 }]
        }, {
          elementType: "labels.text.stroke",
          stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }]
        }, {
          elementType: "labels.text.fill",
          stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }]
        }, { elementType: "labels.icon", stylers: [{ visibility: "off" }] }, {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#f2f2f2" }, { lightness: 19 }]
        }, {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [{ color: "#fefefe" }, { lightness: 20 }]
        }, {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }]
        }]
      };
      var mapId = x === 0 ? "belfast-map" : "dublin-map";
      app.map[x] = new google.maps.Map(document.getElementById(mapId), mapOptions);

      /*
      var infowindow = new google.maps.InfoWindow({
          content: '<div class="mapContent">' +
              '<p>' + markers[x].address + '</p>' +
              '<a class="btn" href="http://maps.google.co.uk/maps?daddr=' + encodeURI(markers[x].address) + '">Get Directions</a>' +
          '</div>'
      });
      */

      var marker = new google.maps.Marker({
        position: mapLatLng,
        map: app.map[x],
        title: "Doublejump",
        icon: markers[x].icon
      });
    }
  },


  productImages: function productImages() {
    //var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
    // advance the other slideshow
    //		slideshows.not(this).cycle('goto', opts.currSlide);
    //});
    var slideshows = $("#main-product-img-gallery");
    $("#thumbs-product-img-gallery .cycle-slide").click(function () {
      var index = $("#thumbs-product-img-gallery").data("cycle.API").getSlideIndex(this);
      slideshows.cycle("goto", index);
    });
  },

  setImgThumbs: function setImgThumbs() {},

  valueOutput: function valueOutput(element) {
    var value = element.value;
    var output = $("#" + element.name + "-output");
    if (output.length == 1) {
      if (element.name === "budget") value = "&pound;" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (element.name === "timeline") {
        if (value == 1) {
          value += " week";
        } else {
          value += " weeks";
        }
      }
      output.html(value);
    }
  },

  rangerslider: function rangerslider() {
    var $inputRange = $('input[type="range"]');
    $inputRange.unbind().rangeslider({
      polyfill: false,
      onSlide: function onSlide(position, value) {
        var elem = this.$element[0].name;
        if (elem === "budget") value = "&pound;" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (elem === "timeline") {
          if (value == 1) {
            value += " week";
          } else {
            value += " weeks";
          }
        }
        $("#" + elem + "-output").html(value);
      }
    });
    // set init value
    for (var i = $inputRange.length - 1; i >= 0; i--) {
      app.valueOutput($inputRange[i]);
    }
  },

  aboutVideo: function aboutVideo(element) {
    var screenWidth = window.screen.width;
    var desktopVersion = element.dataset.desktopSrc;
    var mobileVersion = element.dataset.mobileSrc;

    if (screenWidth > 600) {
      if (element.src !== desktopVersion) {
        element.src = desktopVersion;
      }
    } else {
      if (element.src !== mobileVersion) {
        element.src = mobileVersion;
      }
    }
  },

  selectBtns: function selectBtns() {
    $("#select-btns span").click(function () {
      var selected = $(this);
      var type = selected.attr("data-input");
      var input = $("#" + type);
      if (selected.hasClass("selected")) {
        selected.removeClass("selected");
      } else {
        selected.addClass("selected");
      }
      var selected_value = "";
      $("#select-btns span.selected").each(function (key, row) {
        var value = $(row).attr("data-value");
        selected_value += value + " ";
      });
      input.val($.trim(selected_value));
    });
  },

  autoplayVideoOnHover: function autoplayVideoOnHover() {
    var videos = $(".autoplay-on-hover");

    videos.each(function (key, row) {
      var row = $(row);
      row.on("mouseover", function () {
        row.trigger("play");
      });
      row.on("mouseout", function () {
        row.trigger("pause");
      });
    });
  },

  init: function init() {
    window.scrollTo(0, 0);
    app.dropdownMenus();
    app.mobileMenu();

    if ($(".autoplay-on-hover").length > 0) {
      app.autoplayVideoOnHover();
    }

    if ($(".enquiry_frm_btn").length > 0) {
      app.enquiryDialogForm();
    }

    if ($("#project-items").length > 0) {
      app.projectItems("view-all");
      var btn = $("#filter-btn span");

      btn.click(function (e) {
        var type = e.currentTarget.id;
        e.preventDefault();
        btn.removeClass("active");
        $("#filter-btn #" + type).addClass("active");
        app.projectItems(type);
      });
    }

    // fake lazy load //
    //$(".lazy, .lazy-sub > *").fakeLazyLoad();

    if ($("#select-btns").length > 0) {
      app.selectBtns();
    }

    if ($("#thumbs-product-img-gallery").length > 0) {
      app.productImages();
      app.setImgThumbs();
    }

    if ($('input[type="range"]').length > 0) {
      app.rangerslider();
    }

    if ($("#dublin-map").length > 0) {
      app.initMap();
    }

    app.setPortfolioGallery();

    $(".scrollingtext").bind("marquee", function () {
      var ob = $(this);
      var tw = ob.width();
      var ww = ob.parent().width();
      ob.css({ right: -tw });
      ob.animate({ right: ww }, 30000, "linear", function () {
        ob.trigger("marquee");
      });
    }).trigger("marquee");

    // about
    var aboutVideo = $("#about-video");
    if (aboutVideo.length > 0) {
      app.aboutVideo(aboutVideo[0]);
    }

    var wrapper = null;
    var videos = document.querySelectorAll("video");
    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting === true) {
          var index = entry.target.dataset.videoSrc;
          wrapper = $("video[data-video-src='" + index + "']").parent().prev();
          var file = entry.target.src;
          if (wrapper.length > 0) {
            $(wrapper[0]).attr("style", "background-image:url(" + file + ")");
          }
          entry.target.play();
        } else {
          var index = entry.target.dataset.videoSrc;
          var poster = entry.target.poster;
          wrapper = $("video[data-video-src='" + index + "']").parent().prev();
          entry.target.pause();
          if (wrapper.length > 0) {
            $(wrapper[0]).attr("style", "background-image:url(" + poster + ")");
          }
        }
      });
    });
    videos.forEach(function (video) {
      observer.observe(video);
    });
  }
};

$(document).ready(function () {
  app.init();
});

var options = {
  animationSelector: '[class*="page-transition-"]',
  animateHistoryBrowsing: true,
  plugins: [new SwupPreloadPlugin()]
};
var swup = new Swup(options);
swup.hooks.on("content:replace", app.init);