"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function t(e, o) {
  if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === "object" && (typeof module === "undefined" ? "undefined" : _typeof(module)) === "object") module.exports = o();else if (typeof define === "function" && define.amd) define([], o);else if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === "object") exports["SwupScrollPlugin"] = o();else e["SwupScrollPlugin"] = o();
})(window, function () {
  return function (t) {
    var e = {};function o(n) {
      if (e[n]) {
        return e[n].exports;
      }var r = e[n] = { i: n, l: false, exports: {} };t[n].call(r.exports, r, r.exports, o);r.l = true;return r.exports;
    }o.m = t;o.c = e;o.d = function (t, e, n) {
      if (!o.o(t, e)) {
        Object.defineProperty(t, e, { enumerable: true, get: n });
      }
    };o.r = function (t) {
      if (typeof Symbol !== "undefined" && Symbol.toStringTag) {
        Object.defineProperty(t, Symbol.toStringTag, { value: "Module" });
      }Object.defineProperty(t, "__esModule", { value: true });
    };o.t = function (t, e) {
      if (e & 1) t = o(t);if (e & 8) return t;if (e & 4 && (typeof t === "undefined" ? "undefined" : _typeof(t)) === "object" && t && t.__esModule) return t;var n = Object.create(null);o.r(n);Object.defineProperty(n, "default", { enumerable: true, value: t });if (e & 2 && typeof t != "string") for (var r in t) {
        o.d(n, r, function (e) {
          return t[e];
        }.bind(null, r));
      }return n;
    };o.n = function (t) {
      var e = t && t.__esModule ? function e() {
        return t["default"];
      } : function e() {
        return t;
      };o.d(e, "a", e);return e;
    };o.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    };o.p = "";return o(o.s = 0);
  }([function (t, e, o) {
    "use strict";
    var n = o(1);var r = i(n);function i(t) {
      return t && t.__esModule ? t : { default: t };
    }t.exports = r.default;
  }, function (t, e, o) {
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true });var n = Object.assign || function (t) {
      for (var e = 1; e < arguments.length; e++) {
        var o = arguments[e];for (var n in o) {
          if (Object.prototype.hasOwnProperty.call(o, n)) {
            t[n] = o[n];
          }
        }
      }return t;
    };var r = typeof Symbol === "function" && _typeof(Symbol.iterator) === "symbol" ? function (t) {
      return typeof t === "undefined" ? "undefined" : _typeof(t);
    } : function (t) {
      return t && typeof Symbol === "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t === "undefined" ? "undefined" : _typeof(t);
    };var i = function () {
      function t(t, e) {
        for (var o = 0; o < e.length; o++) {
          var n = e[o];n.enumerable = n.enumerable || false;n.configurable = true;if ("value" in n) n.writable = true;Object.defineProperty(t, n.key, n);
        }
      }return function (e, o, n) {
        if (o) t(e.prototype, o);if (n) t(e, n);return e;
      };
    }();var l = o(2);var s = c(l);var a = o(3);var u = c(a);function c(t) {
      return t && t.__esModule ? t : { default: t };
    }function f(t, e) {
      if (!(t instanceof e)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }function p(t, e) {
      if (!t) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }return e && ((typeof e === "undefined" ? "undefined" : _typeof(e)) === "object" || typeof e === "function") ? e : t;
    }function d(t, e) {
      if (typeof e !== "function" && e !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + (typeof e === "undefined" ? "undefined" : _typeof(e)));
      }t.prototype = Object.create(e && e.prototype, { constructor: { value: t, enumerable: false, writable: true, configurable: true } });if (e) Object.setPrototypeOf ? Object.setPrototypeOf(t, e) : t.__proto__ = e;
    }var h = function (t) {
      d(e, t);function e(t) {
        f(this, e);var o = p(this, (e.__proto__ || Object.getPrototypeOf(e)).call(this));o.name = "ScrollPlugin";o.getOffset = function () {
          var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;switch (r(o.options.offset)) {case "number":
              return o.options.offset;case "function":
              return parseInt(o.options.offset(t), 10);default:
              return parseInt(o.options.offset, 10);}
        };o.onSamePage = function () {
          o.swup.scrollTo(0);
        };o.onSamePageWithHash = function (t) {
          var e = t.delegateTarget;var n = document.querySelector(e.hash);var r = n.getBoundingClientRect().top + window.pageYOffset - o.getOffset(n);o.swup.scrollTo(r);
        };o.onTransitionStart = function (t) {
          if (o.options.doScrollingRightAway && !o.swup.scrollToElement) {
            o.doScrolling(t);
          }
        };o.onContentReplaced = function (t) {
          if (!o.options.doScrollingRightAway || o.swup.scrollToElement) {
            o.doScrolling(t);
          }
        };o.doScrolling = function (t) {
          var e = o.swup;if (!t || e.options.animateHistoryBrowsing) {
            if (e.scrollToElement != null) {
              var n = document.querySelector(e.scrollToElement);if (n != null) {
                var r = n.getBoundingClientRect().top + window.pageYOffset - o.getOffset(n);e.scrollTo(r);
              } else {
                console.warn("Element " + e.scrollToElement + " not found");
              }e.scrollToElement = null;
            } else {
              e.scrollTo(0);
            }
          }
        };var i = { doScrollingRightAway: false, animateScroll: true, scrollFriction: .3, scrollAcceleration: .04, offset: 0 };o.options = n({}, i, t);return o;
      }i(e, [{ key: "mount", value: function t() {
          var e = this;var o = this.swup;o._handlers.scrollDone = [];o._handlers.scrollStart = [];this.scrl = new u.default({ onStart: function t() {
              return o.triggerEvent("scrollStart");
            }, onEnd: function t() {
              return o.triggerEvent("scrollDone");
            }, onCancel: function t() {
              return o.triggerEvent("scrollDone");
            }, friction: this.options.scrollFriction, acceleration: this.options.scrollAcceleration });o.scrollTo = function (t) {
            if (e.options.animateScroll) {
              e.scrl.scrollTo(t);
            } else {
              o.triggerEvent("scrollStart");window.scrollTo(0, t);o.triggerEvent("scrollDone");
            }
          };if (o.options.animateHistoryBrowsing) {
            window.history.scrollRestoration = "manual";
          }o.on("samePage", this.onSamePage);o.on("samePageWithHash", this.onSamePageWithHash);o.on("transitionStart", this.onTransitionStart);o.on("contentReplaced", this.onContentReplaced);
        } }, { key: "unmount", value: function t() {
          this.swup.scrollTo = null;delete this.scrl;this.scrl = null;this.swup.off("samePage", this.onSamePage);this.swup.off("samePageWithHash", this.onSamePageWithHash);this.swup.off("transitionStart", this.onTransitionStart);this.swup.off("contentReplaced", this.onContentReplaced);this.swup._handlers.scrollDone = null;this.swup._handlers.scrollStart = null;window.history.scrollRestoration = "auto";
        } }]);return e;
    }(s.default);e.default = h;
  }, function (t, e, o) {
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true });var n = function () {
      function t(t, e) {
        for (var o = 0; o < e.length; o++) {
          var n = e[o];n.enumerable = n.enumerable || false;n.configurable = true;if ("value" in n) n.writable = true;Object.defineProperty(t, n.key, n);
        }
      }return function (e, o, n) {
        if (o) t(e.prototype, o);if (n) t(e, n);return e;
      };
    }();function r(t, e) {
      if (!(t instanceof e)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }var i = function () {
      function t() {
        r(this, t);this.isSwupPlugin = true;
      }n(t, [{ key: "mount", value: function t() {} }, { key: "unmount", value: function t() {} }]);return t;
    }();e.default = i;
  }, function (t, e, o) {
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true });var n = Object.assign || function (t) {
      for (var e = 1; e < arguments.length; e++) {
        var o = arguments[e];for (var n in o) {
          if (Object.prototype.hasOwnProperty.call(o, n)) {
            t[n] = o[n];
          }
        }
      }return t;
    };function r(t, e) {
      if (!(t instanceof e)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }var i = function t(e) {
      var o = this;r(this, t);this._raf = null;this._positionY = 0;this._velocityY = 0;this._targetPositionY = 0;this._targetPositionYWithOffset = 0;this._direction = 0;this.scrollTo = function (t) {
        if (t && t.nodeType) {
          o._targetPositionY = Math.round(t.getBoundingClientRect().top + window.pageYOffset);
        } else if (parseInt(o._targetPositionY) === o._targetPositionY) {
          o._targetPositionY = Math.round(t);
        } else {
          console.error("Argument must be a number or an element.");return;
        }if (o._targetPositionY > document.documentElement.scrollHeight - window.innerHeight) {
          o._targetPositionY = document.documentElement.scrollHeight - window.innerHeight;
        }o._positionY = document.body.scrollTop || document.documentElement.scrollTop;o._direction = o._positionY > o._targetPositionY ? -1 : 1;o._targetPositionYWithOffset = o._targetPositionY + o._direction;o._velocityY = 0;if (o._positionY !== o._targetPositionY) {
          o.options.onStart();o._animate();
        } else {
          o.options.onAlreadyAtPositions();
        }
      };this._animate = function () {
        var t = o._update();o._render();if (o._direction === 1 && o._targetPositionY > o._positionY || o._direction === -1 && o._targetPositionY < o._positionY) {
          o._raf = requestAnimationFrame(o._animate);o.options.onTick();
        } else {
          o._positionY = o._targetPositionY;o._render();o._raf = null;o.options.onTick();o.options.onEnd();
        }
      };this._update = function () {
        var t = o._targetPositionYWithOffset - o._positionY;var e = t * o.options.acceleration;o._velocityY += e;o._velocityY *= o.options.friction;o._positionY += o._velocityY;return Math.abs(t);
      };this._render = function () {
        window.scrollTo(0, o._positionY);
      };var i = { onAlreadyAtPositions: function t() {}, onCancel: function t() {}, onEnd: function t() {}, onStart: function t() {}, onTick: function t() {}, friction: .7, acceleration: .04 };this.options = n({}, i, e);if (e && e.friction) {
        this.options.friction = 1 - e.friction;
      }window.addEventListener("mousewheel", function (t) {
        if (o._raf) {
          o.options.onCancel();cancelAnimationFrame(o._raf);o._raf = null;
        }
      }, { passive: true });
    };e.default = i;
  }]);
});